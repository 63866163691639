.are-you-doc-container {
  color: #d9d9d9;
  background-color: #333333;
}

.dam {
  justify-content: center;
  max-width: 100%;
}

.custom-5 {
  background-color: #f35b2a;
  color: #ffffff;
  border: none;
  box-shadow: none;
}

.tp-txt{
  font-size: 35px;
}
.lp-txt{
  font-size: 24px;
}

.go-dot-fill {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
}

.gp-tp-txt{
  font-size: 33px;
}
.gp-lp-txt{
  font-size: 22px;
}

@media (max-width: 1280px){
  .tp-txt{
    font-size: 30px;
  }
  .lp-txt{
    font-size: 21px;
  }

  .gp-tp-txt{
    font-size: 28px;
  }
  .gp-lp-txt{
    font-size: 20px;
  }
}

@media (max-width: 1024px) {  
  /* .tp-txt{
    font-size: 34px;
  }
  .lp-txt{
    font-size: 21px;
  } */

  /* .gp-tp-txt{
    font-size: 28px;
  }
  .gp-lp-txt{
    font-size: 20px;
  } */
}

@media (max-width: 768px) {
  .tp-txt{
    font-size: 29px;
  }
  .lp-txt{
    font-size: 15px;
  }

  .gp-tp-txt{
    font-size: 26px;
  }
  .gp-lp-txt{
    font-size: 16px;
  }

  .go-dot-fill {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
}  