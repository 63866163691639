.profile-image-container {
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .camera-icon-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: green;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .profile-image-container img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
  }


.crop-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.crop-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%; 
  max-width: 600px;
  position: relative;
}

.cropper-container {
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
}

.crop-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.zoom-slider {
  width: 100%;
  margin-bottom: 20px;
}

.crop-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.crop-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.crop-buttons button:first-child {
  background-color: #f44336;
  color: white;
}

.crop-buttons button:last-child {
  background-color: #4CAF50;
  color: white;
}

.crop-buttons button:hover {
  opacity: 0.8;
}