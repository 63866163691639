.App {
  text-align: center;
  margin: 0 auto;
  background-color: #e3e3e3;
}
* {
  font-family: sans-serif;
}
@media screen and (max-width: 768px) {
body, html {
  overflow: -moz-scrollbars-none; 
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}
body::-webkit-scrollbar, html::-webkit-scrollbar {
  display: none; 
}
}
.App-logo {
  height: 10vmin;
  pointer-events: none;
}
.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.post {
  margin-bottom: 4em;
}

.post h4 {
  font-size: 2rem;
}
