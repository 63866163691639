.custom-image-gallery {
    display: flex;
    flex-wrap: wrap;
  }
  
  .custom-image-gallery img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  

  .dark-arrows .carousel-control-prev-icon,
  .dark-arrows .carousel-control-next-icon {
    filter: invert(1);
  }
  .carousel-item {
    transition: transform 0.4s ease-in-out !important;
  }
  
  