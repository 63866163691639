.infowindowmodal p{
    font-size: 10px;
    color: black;
    font-weight: 700;
    background-color: azure;
}

.infowindowmodal h1{
    font-size: 7px;
    color: black;
    background-color: azure;
}

.infowindowmodal h2{
    font-size: 10px;
    color: black;
    background-color: azure;
}

