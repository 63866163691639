.user-login-wrapper {
    background: transparent;
    width: 80%;
    margin: auto;
    margin-top: 5vh;
}

.user-login-form {
    background-color: #769dab;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
}

.user-login-form>h1 {
    color: #000000;
    font-size: 3vw;
}

.user-login-item-div {
    /*background-color: white;*/
    border-radius: 5px 5px 5px 5px;
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 1vh;
    margin-bottom: 2vh;
    font-size: 1vh;
}

.user-login-item-div>.labelc {
    color: rgb(0, 0, 0);
    width: 100%;
    height: 2vh;
    align-items: left;
    text-align: left;
    font-size: 3vh;
    display: none;
}

.user-login-item-div>i {
    background-color: white;
    color: rgb(64, 0, 255);
    width: 0%;
    display: none;
}

.user-login-item-password-box-div{
    border-radius: 5px 5px 5px 5px;
    width: 100%;
    border: 0px none;
    background-color: rgb(255, 255, 255);
}

.login-left-icon{
    border-radius: 5px 0px 0px 5px;
    height: 4vh;
    font-size: 3vh;

    width: 10%;
    border: 0px none;
    color: #000000;
  
    background-color: rgb(255, 255, 255);
}
.login-main-text {
    height: 4vh;
    outline-width: 0;
    margin-left: 5px;
    width: 90%;
    border: 0px none;
    font-size: 3vh;
}
.password-main-text {
    height: 4vh;
    outline-width: 0;
    margin-left: 5px;
    width: 87%;
    border: 0px none;
    font-size: 3vh;
}

.login-right-icon i{
    border-radius: 0px 5px 5px 0px;
    height: 4vh;
    font-size: 3vh;
    width: 3%;
    border: 0px none;
    color: #000000;
    background-color: rgb(255, 255, 255);
}

.forgot-password-text{
    color: rgb(13, 13, 221);
    text-align: right;
    font-size: 3vh;
    margin-right: 2vh;
}

.user-login-submit-btn-txt{
    border-radius: 5px 5px 5px 5px;
    height: 5vh;
    background-color: rgb(158, 166, 42);
    color: #ffffff;
    outline-width: 0;
    width: 100%;
    border: 0px none;
    font-size: 3vh;
}

