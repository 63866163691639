@media (max-width: 400px){
  label.star{
    padding: 5px;
  }
}
@media (max-width: 350px){
  label.star{
    padding: 2px;
  }
}

.review-header {
  display: flex;
  flex-direction: column;
  line-height: 35px; /* Reduced line-height */
  align-items: flex-start; /* Changed from 'left' to 'flex-start' */
  font-weight: bold;
  font-size: 25px;
  flex: auto;
}

.header-layout {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  gap: 8px; /* Add gap between elements if needed */
}

.flx {
  display: flex;
}
.col {
  flex-direction: column;
}

.colC {
  align-items: center;
}

.colM {
  justify-content: center;
}

.row {
  flex-direction: row;
}

.rowC {
  justify-content: center;
}

.rowM {
  align-items: center;
  font-weight: bold;
  font-size: 20px;
}




.g4 {
  gap: 4px;
}

.g8 {
  gap: 8px;
}

.g16 {
  gap: 16px;
}

.gAuto {
  gap: auto;
}


.fil {
  flex: auto;
}

.w100 {
  width: 100%;
}

/* text styles */
.h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.sub {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

/* text styles */
.gry100 {
  color: var(--black);
}
.gry40 {
  color: var(--black40);
}
/* .card:hover {
  border: 3px solid var(--yellow);
  scale: 1.05;
  background-color: aqua;
} */

.star-g{
  color: gold;
}

.star-w{
  color: #f4f4f4;
}
