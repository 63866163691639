.custom-navbar {
    padding: 0.5rem 1rem;
    font-weight: bold;
  }
  
  .navbar-brand {
    font-weight: 700; 
  }
  
  .nav-link-custom {
    font-weight: 600;
    padding: 0.5rem 1rem; 
  }
  
  .nav-links {
    display: flex; 
  }
  

.custom-navbar {
    background-color: #f8f9fa;
  }
  
  .navbar-brand {
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .nav-links .nav-link-custom {
    margin-right: 20px;
    font-size: 1rem;
    color: #000;
  }
  
  .navbar-toggler {
    border: none;
    outline: none;
    background-color: transparent;
  }
  
  /* Explicitly style the hamburger icon */
  .navbar-toggler-icon {
    /* background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnMg7VcAc6WkebF912z4ABmiZPlt1Y_TpXbg&s"); */
    width: 1.5em;
    height: 1.5em;
  }
  
  @media (max-width: 992px) {
    .nav-links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .navImgEng{
        width: 40px;
        margin: 0px;
    }
  }

  .navImgEng1{
    width: 30px;
    height: 30px;
  }
  .navImgGer1{
    width: 30px;
    height: 20px;
    margin-right: 0px;
  }

  @media (max-width: 992px) {
    .custom-navbar {
      background-color: #f8f9fa; /* Ensure navbar is still visible */
    }
    
    .nav-links {
      flex-direction: column;
      align-items: flex-start;
    }
    
  }
  
  