.bottom-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.semicircle-background {
    position: absolute;
    bottom: 0;
    width: 650px;
    height: 325px;
    background-color: #ffd900;
    border-top-left-radius: 325px;
    border-top-right-radius: 325px;
    z-index: 0; 
}

.phone-image {
    /* position: relative; */
    z-index: 2; 
    max-width: 285px; 
    height: auto;
    transform: translateY(20px);
}


.btn-orange {
    background-color: #F35B2A;
    color: #fff;
    border: none;
    box-shadow: none !important;
}

.btn-orange:hover {
    color: #fff !important; 
    background-color: #F35B2A !important; 
    box-shadow: none !important;
}
/* @media (max-width: 768px) {
    .bottom-container {
        height: 300px;
    }

    .semicircle-background {
        width: 300px;
        height: 150px;
    }

    .phone-image {
        max-width: 150px;
        transform: translateY(10px);
    }
} */

@media (max-width: 576px) {
    .custom-mine{
        display: none !important;
    }
}
