.no-caret::after {
  display: none !important;
}

.custom-dropdown-item {
  width: 100%;
  background-color: #efefef;
  color: black;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  display: block;
  transition: background-color 0.3s ease; 
}

.custom-dropdown {
  width: 100%;
  position: static;
}

.custom-drop:hover{
  background-color: #ff9be7;
  color:white;
}

.custom-dropdown-item:hover {
  background-color: #ff9be7;
  color: black;
}


