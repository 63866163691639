.image-scroll-container {
  width: 100%;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  margin: 0 auto;
}

.image-scroll-wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.image-scroll-container::-webkit-scrollbar {
  display: none;
}

.image-container {
  flex: 1;
  position: relative;
  width: calc(33.333% - 7px); 
  min-width: 200px; 
}
.scroll-image {
  width: 100%;
  height: 300px; 
  object-fit: cover;
  cursor: default;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(10px);
}

.full-image {
  transition: opacity 0.5s ease;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

@media (max-width: 768px) {
  .image-container {
    width: 100%;
    min-width: 100%;
  }
  
  .image-scroll-wrapper {
    gap: 5px;
  }
}