.mypanel .p-panel-header {
    background-color: #87e8fb;
    color: #3900f6;
}

.p-multiselect-panel {
    background-color: #dfdcdc;
    color: #06181b;
}

