/* Footer Links */
.foo-co ul li {
	padding: 15px 0; /* Padding between the list items */
  }
  
  .foo-co ul li a {
	font-size: 16px;
	color: #bbbbbb;
	text-decoration: none;
	font-weight: 300;
	display: block;
	transition: all 0.3s ease;
  }
  
  .foo-co ul li a:hover {
	color: #ffffff;
	padding-left: 8px;
	transition: all 0.3s ease; /* Ensure the transition is also applied on hover */
  }
  
  /* Social Links */
  .social-links a {
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255, 255, 255, 0.2);
	margin: 0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
  }
  
  .social-links a:hover {
	background-color: #ffffff;
	color: #000000;
	transition: all 0.5s ease; /* Smooth transition on hover */
  }
  

  @media (min-width: 768px) {
	.foo-co ul li {
	  padding: 15px 0; /* Add padding between links on larger screens */
	}
  }
  
  /* Remove padding between text links on mobile screens */
  @media (max-width: 767px) {
	.foo-co ul li {
	  padding: 0; /* No padding on mobile */
	}
  }

  @media (min-width: 1440px) {
	.foo-co ul li {
	  padding-top: 15px; /* Add padding between links on larger screens */
	  padding-bottom: 15px;
	  padding-left: 55px;
	}
  }

  @media (min-width: 1920px) {
	.foo-co ul li {
	  padding-top: 15px;
	  padding-bottom: 15px;
	  padding-left: 55px;
	}
  }


  .foo-co ul li a {
	font-size: 16px;
	color: #bbbbbb;
	text-decoration: none;
	font-weight: 300;
	display: block;
	transition: all 0.3s ease;
	word-break: break-word; 
  }
  
  @media (max-width: 767px) {
	.foo-co ul li a {
	  font-size: 14px;
	}
  }
  