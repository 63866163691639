.profile-container {
    margin-top: 70px;
  }


.toggle-btn {
  
  top: 54px;
  left: 20px;
  z-index: 1000;
  border-radius: 0px;
}

@media (min-width: 768px) {
  .toggle-btn {
    display: none; 
  }
}


.sidebar {
  transition: all 0.3s ease;
}

.profile-container {
  padding-top: 20px;
}
