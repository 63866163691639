.parent-listing {
    background: transparent;
    float: left;
}

body{
    background-color: #ffffff;
}




.pagination-controls {
  text-align: center;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  gap: 5px; 
}

.pagination-controls .btn {
 
  font-size: 16px; /* Default size */
}




.pagination-controls .ellipsis {
  margin: 0 5px;
  color: #A0A0A0;
  font-size: 18px;
}

@media (max-width: 1024px) {
  .pagination-controls .btn {
    padding: 4px 8px; 
    font-size: 14px; 
  }

  .pagination-controls {
    gap: 3px; /* Less spacing between buttons */
  }/* Smaller font size */
}






.search-form {
    background-color: #dbcd37;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
}

.search-form>h1 {
    color: #ffffff;
    font-size: 5vh;
}

.text-search-searchkey {
    border-radius: 5px 5px 5px 5px;
    height: 9vh;
    ;
    outline-width: 0;
    width: 95%;
    border: 0px none;
}

.text-search-submit-btn {
    border-radius: 5px 5px 5px 5px;
    height: 9vh;
    background-color: rgb(142, 184, 244);
    color: #ffffff;
    outline-width: 0;
    width: 100%;
    border: 0px none;
}


.search-results h5{
    font-size: 3vh;
    color: black;
}


.image-profile-div {
    /*background-color: #f5f0ef;*/
    float: left;
    width: 14%;
    max-width: 100%;
    border-radius: 5px 0px 0px 5px;
    height: auto;
    /*width: 100px;
    height: 150px;*/
}


.base-info-div {
    /*background-color: #69f669;*/
    width: 84%;
    float: right;
    margin-left: 5px;
    /*text-wrap: wrap;*/
}

.base-info-div h2 {
    background-color: #ffffff;
    color: #000000;
    font-size: 4vh;
    text-align: left;
    /*text-wrap: wrap;*/
}

.base-info-div h3 {
    background-color: #ffffff;
    color: #000000;
    font-size: 3vh;
    text-align: left;
    /*text-wrap: wrap;*/
}

.base-info-div h4 {
    background-color: #ffffff;
    color: #000000;
    font-size: 2vh;
    text-align: left;
    /*text-wrap: wrap;*/
}

.base-info-div p {
    background-color: #ffffff;
    color: #000000;
    font-size: 3vw;
    text-align: left;
    /*text-wrap: wrap;*/
}

.rating-class-div {

    background-color: #ffffff;
    border-style: solid;
    height: 20px;
    min-height: 4px;
    width: auto;
}

.img-rating-star {
    /*background-color: #f5f0ef;*/
    float: left;
    width: 4%;
    max-width: 100%;
    border-radius: 5px 0px 0px 5px;
    height: auto;
    /*width: 100px;
    height: 150px;*/
}

.base-info-div>.rating-class-div>.p1 {
    background-color: #ffffff;
    color: #000000;
    font-size: 2vw;
    float: left;
    text-align: left;
    /*text-wrap: wrap;*/
}

.base-info-div>.rating-class-div>.p2 {
    background-color: #ffffff;
    color: #737070;
    font-size: 2vw;
    float: left;
    margin-left: 2px;
    text-align: left;
    /*text-wrap: wrap;*/
}




.frame {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 21px 21px;
    justify-content: center;
    position: relative;
    /* width: 1477px; */
    width: 100%;
  }
  
  
  .search-text{
    color:"#f35b2a";
  }
  
  .custom-text {
    color: #595959; 
  }
  
  .custom-bg {
    background-color: #ffd900; 
    color: #fff;
  }
  
  
  .frame .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 21px;
    position: relative;
    width: 65%;
  }
  
  .frame .col {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 350px;
    position: relative;
    /*width: 500px;*/
    min-width: 250px;
    width: 40%;
  }
  
  .frame .frame-doctor-profile {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    gap: 19px;
    position: relative;
    width: 261px;
  }
  
  .frame .doctor-profile-image {
    height: 100px;
    object-fit: cover;
    position: relative;
    width: 100px;
  }
  
  .frame .frame-doctor-name {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1px;
    height: 60px;
    position: relative;
    width: 121.5px;
  }
  
  .frame .doctor-profile-name {
    align-self: stretch;
    color: #0400f3;
    flex: 1;
    font-family: "Inter-Regular", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-shadow: 0px 4px 4px #00000040;
  }
  
  .frame .doctor-occupation {
    align-self: stretch;
    color: #000000;
    flex: 1;
    font-family: "Inter-Regular", Helvetica;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
  }
  
  /* Default styles for larger screens */
  .display-4 {
    font-size: 2.5rem; /* For desktop */
    font-weight: bold;
  }
  
  
  
  /* Styling for small screens */
  @media (max-width: 375px) { /* Extra small mobile phones */
    .display-4 {    font-size: 1rem;
    }
  }
  
  
  .frame .div-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 100%;
  }
  
  .frame .icon-instance-node {
    height: 22px !important;
    position: relative !important;
    width: 22px !important;
  }
  
  .frame .text-wrapper {
    align-self: stretch;
    color: #000000;
    flex: 1;
    font-family: "Courier";
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
  }
  
  .frame .text-wrapper-2 {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 13px;
    font-weight: 400;
    height: 38px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 310px;
  }
  
  .frame .col-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 500px;
    position: relative;
    /*width: 400px;*/
    min-width: 250px;
    width: 28%;
  }
  
  .frame .group {
    height: 16px;
    margin-right: -2px;
    position: relative;
    width: 114px;
  }
  
  .frame .overlap-group {
    background-color: #d9d9d9;
    height: 16px;
    position: relative;
    width: 112px;
  }
  
  .frame .text-wrapper-3 {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 13px;
    font-weight: 400;
    height: 16px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 0;
    width: 112px;
  }
  
  .frame .img {
    height: 23.4px;
    margin-right: -1px;
    position: relative;
    width: 141.67px;
  }
  
  .frame .group-2 {
    height: 23.4px;
    margin-bottom: -1px;
    margin-right: -1px;
    margin-top: -1px;
    position: relative;
    width: 141.67px;
  }
  
  .frame .col-3 {
    align-items: flex-start;
    border: 1px solid;
    border-color: #000000;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: -1px;
    max-width: 300px;
    padding: 0px 10px;
    position: relative;
    /*width: 342px;*/
    min-width: 250px;
    width: 28%;
  }
  
  .frame .div-opening-times {
    align-self: stretch;
    height: 50px;
    position: relative;
    width: 100%;
  }
  
  .frame .div-wrapper {
    background-color: #3691fd;
    height: 50px;
    position: relative;
    /*width: 320px;*/
    width: 100%;
  }
  
  .frame .text-wrapper-4 {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 13px;
    font-weight: 400;
    height: 50px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 0;
    /*width: 320px;*/
    width: 100%;
  }
  
  .frame .frame-day {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 16px;
    height: 25px;
    position: relative;
    width: 100%;
  }
  
  .frame .group-slot {
    align-self: stretch;
    flex: 1;
    flex-grow: 1;
    position: relative;
  }
  
  .frame .overlap-group-2 {
    background-color: #d9d9d9;
    height: 25px;
    position: relative;
    width: 96px;
  }
  
  .frame .text-wrapper-5 {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 13px;
    font-weight: 400;
    height: 25px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 0;
    width: 96px;
  }
  
  .frame .overlap-wrapper {
    align-self: stretch;
    flex: 1;
    flex-grow: 1;
    margin-right: -2px;
    position: relative;
  }
  
  
  

  
 
  
  
  .selected_btn{
    border-color: #A0A0A0;
    background-color: #A0A0A0;
    color: white;
    box-shadow: none;
  }
  
  .selected_btn1{
    border: solid 1px #A0A0A0;
    color: #A0A0A0;
    box-shadow: none;
    
  }

  .selected_btn,
.selected_btn:hover {
    border-color: #A0A0A0;
    background-color: #A0A0A0;
    color: white;
    box-shadow: none;
}

.selected_btn1,
.selected_btn1:hover {
    border: solid 1px #A0A0A0;
    color: #A0A0A0;
    box-shadow: none;
}



