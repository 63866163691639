.container {
  width: 100%;
  padding: 20px;
}

/* Profile Section */
.profile-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.profile-image img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  position: absolute;
  top: -90px;
  left: 80px;
}



.profile-header {
  font-size: 23px;
  font-weight: bold;
  padding-left: 10px;
}

.profile-header1 {
  font-size: 17px;
  padding-left: 10px;
}

.p-h-cont {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  padding: 0px;
  margin: 0px;
}

.p-h-cont1 {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.p-h-c {
  padding-top: 20px;
  padding-left: 8px;
}

.p-h-i {
  margin-right: 10px;
  color: blueviolet;
  width: 15px;
  height: 15px;
  margin-top: 3px;
  min-width: 15px;
  min-height: 15px;
}

.p-h-c-r {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.p-h-c-r-h {
  padding-right: 15px;
  display: flex;
  justify-content: end;
  align-items: end;
}

.profile-info {
  flex: 1;
  padding-left: 300px;
}

.profile-info h3 {
  margin-bottom: 10px;
}

.profile-info p {
  margin: 5px 0;
}

.buttons {
  padding-top: 30px;
  margin-right: 10px;
  cursor: pointer;
}




.p-r-b1 {
  background-color: #f35b2a;
  margin: 10px 0px;
}

.p-r-b1:hover {
  background-color: white;
  color: #f35b2a;
  margin: 10px 0px;
}


.cont-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  
}

.doc-cont{
  padding: 20px;
  font-size: 20px;
  display: flex;
  border-bottom: 1px solid #ddd;
}

.cont-top-c {
  font-size: 40px;
  font-weight: bold;
}

.cont-top-i {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cont-im {
  position: relative;
  display: inline-block;
  text-align: center;
}

.cont-im img {
  max-width: 150px; 
  height: auto;
}

.cont-top-i-c {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: grey; 
  font-size: 20px;
  font-weight: bold;
  pointer-events: none; 
}

.custom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.custom-column {
  width: 32%; 
  padding: 10px;
}

.custom-company-section, .custom-media-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  min-height: 200px;
  word-wrap: break-word; /* Allows long words to break and wrap */
  overflow-wrap: break-word; /* Modern alternative to word-wrap */
}



.custom-subheading{
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
  color: #555;
  white-space: normal;
  word-break: break-word; 
}

.custom-subheading1{
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
  color: #555;
  display: flex;
  flex-wrap: wrap;
}

.custom-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  
}


.custom-subheader {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
  color: #555;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  padding: 0;
}

hr {
  border: 0;
  height: 1px;
  background: #ddd;
  margin: 10px 0;
}

.custom-image-modal {
  margin-top: 20px;
}

.custom-network-section, .custom-review-section {
  width: 100%;
  padding: 20px;
  background-color: #f4f4f4;
  margin-top: 30px;
  border-radius: 8px;
}

.custom-review-header{
  display: flex;
  justify-content: space-between;
}

.review-btn{
  background-color: #f35b2a;
}

.review-btn:hover{
  background-color: white;
  color: #f35b2a;
}

.custom-network-header, .custom-review-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .custom-container {
    padding: 10px;
  }
  
  .custom-column {
    width: 100%; /* Columns take full width on smaller screens */
    padding: 5px;
  }

  .custom-company-section, .custom-media-section, .custom-network-section {
    padding: 15px; /* Reduce padding for mobile devices */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Smaller shadow for mobile */
  }

  .custom-header p {
    font-size: 16px; /* Adjust font size for better mobile readability */
  }

  .custom-subheader {
    font-size: 12px;
  }

  .custom-network-header {
    font-size: 18px; /* Slightly smaller for mobile */
  }

  .custom-image-modal {
    margin-top: 15px; /* Reduce margin for mobile */
  }
}

@media (max-width: 480px) {
  .custom-header p {
    font-size: 14px; /* Further reduce font size for very small screens */
  }

  .custom-subheader {
    font-size: 11px; /* Adjust subheader font size */
  }

  .custom-company-section, .custom-media-section {
    padding: 10px; /* Even smaller padding for very small screens */
  }

  .custom-network-header {
    font-size: 16px;
  }

  hr {
    margin: 5px 0; /* Reduce margin around hr for mobile */
  }
}




@media (max-width: 768px) {
  .cont-top {
    flex-direction: column-reverse; 
    text-align: center;
  }

  .cont-top-i {
    margin-bottom: 20px;
  }
  
  .container{
   
  }

  .cont-im img {
    width: 900px;
    height: 100px;
  }
}


@media (max-width: 768px) {
  .profile-section {
      flex-direction: column;
      align-items: center;
  }

  .profile-image img {
      margin-top: 20px;
      position: static;
      margin-bottom: 20px;
      width: 150px;
      height: 150px;
  }

  .profile-info {
      padding-left: 0;
      text-align: center;
  }

  .p-h-c {
      padding-top: 10px;
      padding-left: 0;
      text-align: center;
  }

  .p-h-c-r {
      align-items: center;
      justify-content: center;
  }

  .p-h-c-r-h {
      justify-content: center;
  }

  .buttons {
      justify-content: center;
      text-align: center;
      padding-top: 20px;
      margin-right: 0;
  }

  .description-map-section {
      flex-direction: column;
  }

  .contMap-content {
      width: 100%;
  }

  .contMap-map {
      width: 100%;
      margin-top: 20px;
  }
}

@media (max-width: 1024px) {
  .profile-image img {
    width: 150px;
    height: 150px;
  }

}

@media (max-width: 425px) {
  .profile-image img {
      width: 120px;
      height: 120px;
     
  }

  .profile-header {
      font-size: 18px;
      margin-top: 20px;
  }

  .profile-header1 {
      font-size: 14px;
  }

  .p-h-c {
      padding-top: 5px;
  }

  .p-h-c-r-h {
      padding-right: 0;
  }
}

.see-more{
  border-width: 0px;
  background-color: #f35b2a;
  border-radius: 20px;
  margin: 20px 0px;
  padding: 10px;
  font-size: 20px;
  color: white;
}








.custom-review-section {
  width: 100%;
  padding: 20px;
  background-color: #f4f4f4;
  margin-top: 30px;
  border-radius: 8px;
}

.custom-review-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.review-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rating-bar {
  width: 60%;
}

.rating-bar-star {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.rating-bar-star span {
  width: 20px;
}

.bar {
  width: 100%;
  background-color: #ddd;
  height: 8px;
  border-radius: 5px;
  margin-left: 10px;
  position: relative;
}

.fill {
  background-color: #f35b2a;
  height: 100%;
  border-radius: 5px;
}

.overall-rating {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overall-rating h1 {
  font-size: 40px;
  margin: 0;
}

.star-icons {
  color: #f35b2a;
  font-size: 24px;
}

.review-btn {
  background-color: #f35b2a;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.review-btn:hover {
  background-color: white;
  color: #f35b2a;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .review-summary {
    flex-direction: column-reverse; 
    align-items: flex-start; 
  }

  .rating-bar {
    width: 100%; /* Full width on smaller screens */
  }

  .overall-rating {
    margin-bottom: 20px; /* Add spacing between the rating and bars */
    align-items: flex-start;
  }
}


div.stars {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input.star { display: none; }

.Reviews{
  display: flex;
  flex-direction: column;
  width: 750px;
}

label.star {
  float: right;
  padding: 10px;
  font-size: 36px;
  color: #444;
  transition: all .2s;
}

input.star:checked ~ label.star:before {
  content: '\f005';
  color: #FD4;
  transition: all .25s;
}

input.star-5:checked ~ label.star:before {
  color: #FE7;
  text-shadow: 0 0 20px #952;
}

input.star-1:checked ~ label.star:before { color: #F62; }


label.star:before {
  content: '\f006';
  font-family: FontAwesome;
}



.custom-review-header {
  display: flex;
}




@media (max-width: 768px) {
  .custom-review-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}









.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.see-more-button {
  background-color: #f35b2a;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-color: #f9f9f9;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.see-more-button:hover {
  background-color: white;
  color: #f35b2a;
}




@media (max-width:1280px){
  .custom-subheading{
    font-size: 12px;
  }
}