  .modal-content-structure {
    background-color: #f35b2a;
    color: white;
    text-align: center;
    width: 100%;
    padding: 20px;
    height: 550px;
  }

  .top-section {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .logo .flower-icon {
    width: 230px;
    height: 200px;
    background-size: contain; 
    margin: 0 auto 20px auto;
  }

  .title {
    font-size: 36px;
    font-weight: bold;
  }

  .star-text {
    color: white;
  }

  .subtitle {
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .bottom-section {
    display: flex;
    justify-content: space-around;
  }

  .item {
    text-align: center;
  }

  .item .flower-icon1 {
    width: 70px;
    height: 60px;
    background-size: contain;
    margin: 0 auto 10px auto;
  }

  .item p {
    margin-top: 10px;
    font-size: 14px;
  }

  /* Responsive adjustments */
  @media (max-width: 1024px) {
    /* Adjust overall modal size */
    .modal-content-structure {
      height: auto;
      padding: 10px;
    }

    /* Adjust title size */
    .title {
      font-size: 30px;
    }

    .subtitle {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .logo .flower-icon {
      width: 180px;
      height: 160px;
    }

    .bottom-section {
      flex-direction: column; /* Stack items vertically */
      align-items: center;
    }

    .item {
      margin-bottom: 15px;
    }

    .item .flower-icon1 {
      width: 50px;
      height: 45px;
    }

    .item p {
      font-size: 13px;
    }
  }

  /* Further responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .title {
      font-size: 24px;
    }

    .subtitle {
      font-size: 12px;
    }

    .logo .flower-icon {
      width: 120px;
      height: 100px;
    }

    .bottom-section {
      margin-top: 20px;
    }

    .item .flower-icon1 {
      width: 40px;
      height: 35px;
    }

    .item p {
      font-size: 12px;
    }
  }

  @media (max-width: 576px) {
    .top-section {
      margin-bottom: 15px;
    }

    .logo .flower-icon {
      width: 80px;
      height: 80px;
    }

    .title {
      font-size: 20px;
    }

    .subtitle {
      font-size: 10px;
    }

    .bottom-section {
      margin-top: 15px;
    }

    .item .flower-icon1 {
      width: 30px;
      height: 25px;
    }

    .item p {
      font-size: 10px;
    }
  }


  @media (max-width: 1920px) {
    .my-cards { margin: 20px auto; width: 85%; }
  }

  @media (max-width: 1440px) {
    .my-cards { width: 95%; margin-left: 60px; }
  }

  @media (max-width: 1280px), (max-width: 1024px), (max-width: 768px) {
    .my-cards, .Bookings { width: 100%; }
    .Bookings-btn, .Bookings-btn1 { font-size: 11px; }
  }

















  .my-cards {
    display: flex;
    color: black;
    height: 100%; /* Ensures the height fills available space */
    min-height: 300px; /* Set a minimum height */
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 20px;
    margin-left: 0;
  }


  @media (max-width:10000px){
    .prof-p-text{
      color:#f35b2a;
    }
    .title-div{
      display:flex;
      flex-direction: column;
      font-weight: bold;
      padding-left: 20px;
      margin-top: 12px;
    }
    .star-logo{
      margin-right: 2px;
      font-size: 30px;
    }
    .star-span{
      margin: 0px;
      font-size: 25px;
    }
    .prof-p{
      font-size:16px;
    }
    .pfp-cont{
      align-items: center;
      display: flex;
      
    }
    .left-cont{
      display:flex;
      align-items: start;
      padding: 0px 8px;
      margin: 8px 0px;
    }
    .docpfp{
      border-radius: 999px;
      height: 80px;
      width: 80px;
      margin-top: 10px;
    }
    .my-cards{
      display:flex;
      color:black;
      margin:10px 0px;
      width:100%;
      background-color:#f2f2f2;
      border-radius:20px;
      padding:8px;
      height:auto;
    }
    .bhenChod{
      width:50%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      text-align:center;
      margin:10px;
      background-color:#a0a0a0;
      border-radius:20px;
      
    }
    .bhenChod-header{
      display:flex;
      justify-content:center;
      color:#ffd900;
      font-size:25px;
      font-weight:bold;
      height:25%;
    }
    .bhenChod-footer{
      font-size:13px;
      height:75%;
      color:white;
      padding:15px 3px;
      line-height:1.25;
      align-items:center;
      white-space: normal;
      word-wrap: break-word;
      word-break: break-word;
      overflow-wrap: break-word;
    }
    
    .Bookings-text{
      margin-right:48px;
      cursor: pointer;
    }
    .Bookings-text-eng{
      margin-right:10px;
      font-size: 20px;
      cursor: pointer;
    }
    .Bookings-text1{
      margin-left:0px;
      font-size: 22px;
    }
    .Bookings-btn{
      width:33%;
      border-width:0px;
      background-color:#a0a0a0;
      color:white;
      border-radius: 20px;
      font-size:15px;
    }
    .Bookings-btn1{
      width:33%;
      border-width:0px;
      background-color:#f2f2f2;
      color:#f2f2f2;
      border-radius: 20px;
      font-size:15px;
    }
    .Bookings-label{
      width:33%;
      color:#7f7f7f;
      
    }
    .timings{
      height:13%;
      margin-top:3px;
      margin-bottom: 9px;
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
  }



  @media (max-width:1440px){
    .prof-p-text{
      color:#f35b2a;
      
    }
    .title-div{
      display:flex;
      flex-direction: column;
      font-weight: bold;
      padding-left: 20px;
      margin-top: 12px;
    }
    .star-logo{
      margin-right: 2px;
      font-size: 30px;
    }
    .star-span{
      margin: 0px;
      font-size: 25px;
    }
    .prof-p{
      font-size:12px;
    }
    .pfp-cont{
      display: flex;
      height: 30%;
    }
    .left-cont{
      display:flex;
      align-items: start;
      padding: 4px;
      margin: 0px;
    }
    .my-cards{
      display:flex;
      color:black;
      height:auto;
      margin:10px 0px;
      width:100%;
      background-color:#f2f2f2;
      border-radius:20px;
      margin-left: 0px;
    }
    .bhenChod{
      width:50%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      text-align:center;
      margin:5px 10px;
      background-color:#a0a0a0;
      border-radius:20px;
    }
    .bhenChod-header{
      color:#ffd900;
      font-size:25px;
      font-weight:bold;
      height:25%;
    }
    .bhenChod-footer{
      font-size:12px;
      height:75%;
      color:white;
      line-height:1.25;
      padding: '2px'
    }
    .Bookings{
      width:40%;
      margin:10px;
      
      border-radius:22px;
    }
    .Bookings-text{
      margin-right:10px;
      cursor: pointer;
      font-size:25px;
    }
    .Bookings-text-eng{
      margin-right:10px;
      font-size: 20px;
      cursor: pointer;
    }
    .Bookings-text1{
      margin-left:0px;
      font-size:20px;
    }
    .Bookings-btn{
      width:33%;
      border-width:0px;
      background-color:#a0a0a0;
      color:white;
      border-radius: 20px;
      font-size: 11px;
    }
    .Bookings-btn1{
      width:33%;
      border-width:0px;
      background-color:#f2f2f2;
      color:#f2f2f2;
      border-radius: 20px;
      font-size: 11px;
    }
    .Bookings-label{
      width:33%;
      color:#7f7f7f;
      
    }
    .timings{
      height:14%;
      margin: 6px 0px;
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
  }



  @media (max-width:1280px){
    .prof-p-text{
      color:#f35b2a;
      margin:0px;
      font-size: 13px;
    }
    .title-div{
      display:flex;
      flex-direction: column;
      font-weight: bold;
      padding-left: 20px;
      margin-top: 12px;
    }
    .star-logo{
      margin-right: 2px;
      font-size: 27px;
    }
    .star-span{
      margin: 0px;
      font-size: 20px;
    }
    .my-cards{
      display:flex;
      color:black;
      margin-top:20px;
      margin-bottom:20px;
      width:100%;
      background-color:#f2f2f2;
      border-radius:20px;
    }
    .left-cont{
      display:flex;
      align-items: start;
      text-align: left;
      padding: 4px;
      font-size: 14px;
      margin: 0px;
    }
    .bhenChod{
      width:50%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      text-align:center;
      margin:10px 5px 10px;
      background-color:#a0a0a0;
      border-radius:20px;
    }
    .bhenChod-header{
      color:#ffd900;
      font-size:20px;
      font-weight:bold;
      height:25%;
    }
    .bhenChod-footer{
      font-size:14px;
      height:75%;
      color:white;
      padding-top:10px;
      line-height:1.25;
    }
    .Bookings{
      width:40%;
      margin:10px 0px 10px;
      border-radius:22px;
    }
    .Bookings-text{
      font-size:20px;
      margin-right:0px;
      cursor: pointer;
    }
    .Bookings-text-eng{
      margin-right:10px;
      font-size: 15px;
      cursor: pointer;
    }
    .Bookings-text1{
      font-size:15px;
      margin-left:0px;
    }
    .Bookings-btn{
      width:33%;
      border-width:0px;
      background-color:#a0a0a0;
      color:white;
      border-radius: 20px;
      font-size: 11px;
    }
    .Bookings-btn1{
      width:33%;
      border-width:0px;
      background-color:#f2f2f2;
      color:#f2f2f2;
      border-radius: 20px;
      font-size: 11px;
    }
    .Bookings-label{
      
      width:33%;
      color:#7f7f7f;
      font-size: 15px;
    }
    .timings{
      height:14%;
      margin:6px 0px;
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
  }



  @media (max-width:1200px){
    .prof-p-text{
      color:#f35b2a;
      margin:0px;
      font-size: 14px;
    }
    .title-div{
      display:flex;
      flex-direction: column;
      font-weight: bold;
      padding-left: 0px;
      margin-top: 12px;
      align-items: center;
      width: 100%;
      text-align: center;
    }
    .docpfp{
      border-radius: 999px;
      height: 60px;
      width: 60px;
      margin-top: 10px;
    }
    .left-cont{
      display:flex;
      align-items: start;
      text-align: left;
      padding: 5px;
      font-size: 12px;
      margin: 0px;
    }
    .star-logo{
      margin-right: 2px;
      font-size: 22px;
    }
    .star-span{
      margin: 0px;
      font-size: 18px;
    }
    .my-cards{
      display:flex;
      color:black;
      margin-top:20px;
      margin-bottom:20px;
      width:100%;
      background-color:#f2f2f2;
      border-radius:20px;
    }
    .bhenChod{
      width:50%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      text-align:center;
      margin:10px 5px 10px;
      background-color:#a0a0a0;
      border-radius:20px;
    }
    .bhenChod-header{
      color:#ffd900;
      font-size:18px;
      font-weight:bold;
      align-items:center;
      display:flex;
      height:25%;
    }
    .bhenChod-footer{
      font-size:14px;
      height:75%;
      color:white;
      padding-top:10px;
      line-height:1.25;
    }
    .Bookings{
      width:40%;
      margin:5px 0px;
      border-radius:22px;
    }
    .Bookings-text{
      font-size:18px;
      margin-right:0px;
      cursor: pointer;
    }
    .Bookings-text-eng{
      margin-right:10px;
      font-size: 15px;
      cursor: pointer;
    }
    .Bookings-text1{
      font-size:15px;
      margin-left:0px;
    }
    .Bookings-btn{
      width:33%;
      border-width:0px;
      background-color:#a0a0a0;
      color:white;
      border-radius: 20px;
      font-size: 10px;
    }
    .Bookings-btn1{
      width:33%;
      border-width:0px;
      background-color:#f2f2f2;
      color:#f2f2f2;
      border-radius: 20px;
      font-size: 10px;
    }
    .Bookings-label{
      width:33%;
      color:#7f7f7f;
      font-size: 11px;
    }
    .timings{
      height:14%;
      margin:12px 10px;
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
  }

  @media (max-width:992px){
    .prof-p-text{
      color:#f35b2a;
      margin:0px;
      font-size: 12px;
    }
    .title-div{
      display:flex;
      flex-direction: column;
      font-weight: bold;
      padding-left: 0px;
      margin-top: 12px;
      align-items: center;
      width: 100%;
      text-align: center;
    }
    .docpfp{
      border-radius: 999px;
      height: 60px;
      width: 60px;
      margin-top: 10px;
    }
    .left-cont{
      display:flex;
      align-items: start;
      text-align: left;
      padding: 0px 5px;
      font-size: 12px;
      margin: 8px 0px;
    }
    .star-logo{
      margin-right: 2px;
      font-size: 22px;
    }
    .star-span{
      margin: 0px;
      font-size: 18px;
    }
    .my-cards{
      display:flex;
      color:black;
      margin-top:20px;
      margin-bottom:20px;
      width:100%;
      background-color:#f2f2f2;
      border-radius:20px;
    }
    .bhenChod{
      width:50%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      text-align:center;
      margin:10px 5px 10px;
      background-color:#a0a0a0;
      border-radius:20px;
    }
    .bhenChod-header{
      color:#ffd900;
      font-size:18px;
      font-weight:bold;
      align-items:center;
      display:flex;
      height:25%;
    }
    .bhenChod-footer{
      font-size:10px;
      height:75%;
      color:white;
      padding-top:10px;
      line-height:1.25;
    }
    .Bookings{
      width:40%;
      margin:5px 0px;
      border-radius:22px;
    }
    .Bookings-text{
      font-size:14px;
      margin-right:0px;
      cursor: pointer;
    }
    .Bookings-text-eng{
      font-size:12px;
      margin-right:0px;
      cursor: pointer;
    }
    .Bookings-text1{
      font-size:15px;
      margin-left:0px;
    }
    .Bookings-btn{
      width:33%;
      border-width:0px;
      background-color:#a0a0a0;
      color:white;
      border-radius: 20px;
      font-size: 9px;
    }
    .Bookings-btn1{
      width:33%;
      border-width:0px;
      background-color:#f2f2f2;
      color:#f2f2f2;
      border-radius: 20px;
      font-size: 9px;
    }
    .Bookings-label{
      width:33%;
      color:#7f7f7f;
      font-size: 11px;
    }
    .timings{
      height:14%;
      margin:12px 10px;
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
  }

  