.scroll-container {
    position: relative;
  }
  
  .scroll-indicator {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .scroll-indicator button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: lightgray;
    border: none;
    cursor: pointer;
  }
  
  .scroll-indicator button.active {
    background-color: darkgray;
  }
  
  .scroll-content {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
  
  .scroll-item {
    min-width: 100vw;
    height: 500px;
    scroll-snap-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
  
  .my-cards1 {
    display: flex;
    flex-direction: column;
    color: black;
    margin: 10px 0px;
    /* width: 100%; */
    background-color: #f2f2f2;
    border-radius: 20px;
    padding: 8px;
    height: auto; /* Set height to auto to allow expansion */
    /* overflow: hidden;  */
  }
  
  