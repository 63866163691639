.frame {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 21px 21px;
  justify-content: center;
  position: relative;
  /* width: 1477px; */
  width: 100%;
}


.search-text{
  color:"#f35b2a";
}

.custom-text {
  color: #595959; 
}

.custom-bg {
  background-color: #ffd900; 
  color: #fff;
}


.frame .div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 21px;
  position: relative;
  width: 65%;
}

.frame .col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 350px;
  position: relative;
  /*width: 500px;*/
  min-width: 250px;
  width: 40%;
}

.frame .frame-doctor-profile {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 19px;
  position: relative;
  width: 261px;
}

.frame .doctor-profile-image {
  height: 100px;
  object-fit: cover;
  position: relative;
  width: 100px;
}

.frame .frame-doctor-name {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1px;
  height: 60px;
  position: relative;
  width: 121.5px;
}

.frame .doctor-profile-name {
  align-self: stretch;
  color: #0400f3;
  flex: 1;
  font-family: "Inter-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-shadow: 0px 4px 4px #00000040;
}

.frame .doctor-occupation {
  align-self: stretch;
  color: #000000;
  flex: 1;
  font-family: "Inter-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

/* Default styles for larger screens */
.display-4 {
  font-size: 2.5rem; /* For desktop */
  font-weight: bold;
}



/* Styling for small screens */
@media (max-width: 375px) { /* Extra small mobile phones */
  .display-4 {    font-size: 1rem;
  }
}


.frame .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
}

.frame .icon-instance-node {
  height: 22px !important;
  position: relative !important;
  width: 22px !important;
}

.frame .text-wrapper {
  align-self: stretch;
  color: #000000;
  flex: 1;
  font-family: "Courier";
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.frame .text-wrapper-2 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 38px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 310px;
}

.frame .col-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 500px;
  position: relative;
  /*width: 400px;*/
  min-width: 250px;
  width: 28%;
}

.frame .group {
  height: 16px;
  margin-right: -2px;
  position: relative;
  width: 114px;
}

.frame .overlap-group {
  background-color: #d9d9d9;
  height: 16px;
  position: relative;
  width: 112px;
}

.frame .text-wrapper-3 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 16px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 112px;
}

.frame .img {
  height: 23.4px;
  margin-right: -1px;
  position: relative;
  width: 141.67px;
}

.frame .group-2 {
  height: 23.4px;
  margin-bottom: -1px;
  margin-right: -1px;
  margin-top: -1px;
  position: relative;
  width: 141.67px;
}

.frame .col-3 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #000000;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: -1px;
  max-width: 300px;
  padding: 0px 10px;
  position: relative;
  /*width: 342px;*/
  min-width: 250px;
  width: 28%;
}

.frame .div-opening-times {
  align-self: stretch;
  height: 50px;
  position: relative;
  width: 100%;
}

.frame .div-wrapper {
  background-color: #3691fd;
  height: 50px;
  position: relative;
  /*width: 320px;*/
  width: 100%;
}

.frame .text-wrapper-4 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 50px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  /*width: 320px;*/
  width: 100%;
}

.frame .frame-day {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 16px;
  height: 25px;
  position: relative;
  width: 100%;
}

.frame .group-slot {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.frame .overlap-group-2 {
  background-color: #d9d9d9;
  height: 25px;
  position: relative;
  width: 96px;
}

.frame .text-wrapper-5 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 25px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 96px;
}

.frame .overlap-wrapper {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  margin-right: -2px;
  position: relative;
}





@keyframes spin_words{
  11%{
      transform: translateY(-100%);
  }
  22%{
      transform: translateY(-200%);
  }
  33%{
      transform: translateY(-300%);
  }
  44%{
      transform: translateY(-400%);
  }
  55%{
      transform: translateY(-500%);
  }
  66%{
      transform: translateY(-600%);
  }
  77%{
      transform: translateY(-700%);
  }
  88%{
      transform: translateY(-800%);
  }
  100%{
    transform: translateY(-900%);
  }
}

.display-3 {
  font-size: 3rem;
  background-color: #f8f9fa;
  font-weight: bold;
}

@media (max-width: 7840px){
  .words1{
    font-size: 64px;
  }
  .display-3 {
    font-size: 4rem; 
  }
  .sideHo{
    margin-left: 142px;
  }
  .imgCont{
    /* height: 270px; */
    width: 474px;
  }
  .navCont{
    padding: 10px;
    margin-bottom:120px;
    margin-left:50px;
    display: flex;
    height: auto;
  }
  .navContText{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navContText1{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }.navContText2{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navImgEng{
    cursor: pointer;
    width: 100px;
    height: 30px;
  }
  .navImgGer{
    cursor: pointer;
    width:  40px;
    height: 30px;
  }
  .imgDiv{
    width: 50%;
  }
  .contDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 50%; 
  }
 

  .words {
    height: 90px; 
    overflow: hidden; 
    position: relative;
  }
  
  .words span {
    background-color: #ffffff;
    height: 100px;
    display: block;
    text-align: start;
    color: #f35b2a;
    animation: spin_words 8s infinite;
  }
}

@media (max-width: 3840px){
  .words1{
    font-size: 64px;
  }
  .display-3 {
    font-size: 4rem; 
  }
  .sideHo{
    margin-left: 142px;
  }
  .imgCont{
    /* height: 270px; */
    width: 474px;
  }
  .navCont{
    padding: 10px;
    margin-bottom:120px;
    margin-left:50px;
    display: flex;
    height: auto;
  }
  .navContText{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navContText1{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }.navContText2{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navImgEng{
    cursor: pointer;
    width: 100px;
    height: 30px;
  }
  .navImgGer{
    cursor: pointer;
    width:  40px;
    height: 30px;
  }
  .imgDiv{
    width: 50%;
  }
  .contDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 50%; 
  }


  .words {
    height: 90px; 
    overflow: hidden; 
    position: relative;
  }
  
  .words span {
    background-color: #ffffff;
    height: 100px;
    display: block;
    text-align: start;
    color: #f35b2a;
    animation: spin_words 8s infinite;
  }
}

@media (max-width: 2560px){
  .words1{
    font-size: 64px;
  }
  .display-3 {
    font-size: 4rem; 
  }
  .sideHo{
    margin-left: 142px;
  }
  .imgCont{
    /* height: 270px; */
    width: 474px;
  }
  .navCont{
    padding: 10px;
    margin-bottom:120px;
    margin-left:50px;
    display: flex;
    height: auto;
  }
  .navContText{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navContText1{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }.navContText2{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navImgEng{
    cursor: pointer;
    width: 100px;
    height: 30px;
  }
  .navImgGer{
    cursor: pointer;
    width:  40px;
    height: 30px;
  }
  .imgDiv{
    width: 50%;
  }
  .contDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 50%; 
  }


  .words {
    height: 90px; 
    overflow: hidden; 
    position: relative;
  }
  
  .words span {
    background-color: #ffffff;
    height: 100px;
    display: block;
    text-align: start;
    color: #f35b2a;
    animation: spin_words 8s infinite;
  }
}


@media (max-width: 1920px) {
  .words1{
    font-size: 64px;
  }
  .display-3 {
    font-size: 4rem; 
  }
  .sideHo{
    margin-left: 140px;
  }
  .imgCont{
    /* height: 270px; */
    width: 474px;
  }
  .navCont{
    padding: 10px;
    margin-bottom:120px;
    margin-left:50px;
    display: flex;
    height: auto;
  }
  .navContText{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navContText1{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }.navContText2{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navImgEng{
    cursor: pointer;
    width: 100px;
    height: 30px;
  }
  .navImgGer{
    cursor: pointer;
    width:  40px;
    height: 30px;
  }
  .imgDiv{
    width: 50%;
  }
  .contDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 50%; 
  }


  .words {
    height: 90px; 
    overflow: hidden; 
    position: relative;
  }
  
  .words span {
    background-color: #ffffff;
    height: 100px;
    display: block;
    text-align: start;
    color: #f35b2a;
    animation: spin_words 8s infinite;
  }

  .pad-top{
    padding-top: 50px;
  }
}



/* 2xl */
@media (max-width: 1440px) {
  .words1{
    font-size: 48px;
  }
  .display-3 {
    font-size: 4rem; 
  }
  .sideHo{
    margin-left: 118px;
  }
  .imgCont{
    /* height: 212px; */
    width: 375px;
  }
  .navCont{
    padding: 10px;
    margin-bottom:120px;
    margin-left:80px;
    display: flex;
    height: auto;
  }
  .navContText{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navContText1{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navContText2{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navImgEng{
    cursor: pointer;
    width: 40px;
    height: 30px;
  }
  .navImgGer{
    cursor: pointer;
    width:  40px;
    height: 30px;
  }
  .imgDiv{
    width: 50%;
  }
  .contDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 50%; 
  }
 

  .words {
    height: 90px; 
    overflow: hidden; 
    position: relative;
  }
  
  .words span {
    background-color: #ffffff;
    height: 100px;
    display: block;
    text-align: start;
    color: #f35b2a;
    animation: spin_words 8s infinite;
  }
  .pad-top{
    padding-top: 50px;
  }
}


@media (max-width: 1280px) {
  .words1{
    font-size: 48px;
  }
  .display-3 {
    font-size: 4rem; 
  }
  .sideHo{
    margin-left: 75px;
  }
  .imgCont{
    /* height: 212px; */
    width: 354px;
  }
  .navCont{
    padding: 10px;
    margin-bottom:120px;
    margin-left:80px;
    display: flex;
    height: auto;
  }
  .navContText{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navContText1{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navContText2{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    color: #595959;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
  }
  .navImgEng{
    cursor: pointer;
    width: 40px;
    height: 30px;
  }
  .navImgGer{
    cursor: pointer;
    width:  40px;
    height: 30px;
  }
  .imgDiv{
    width: 50%;
  }
  .contDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 50%; 
  }
 

  .words {
    height: 90px; 
    overflow: hidden; 
    position: relative;
  }
  
  .words span {
    background-color: #ffffff;
    height: 100px;
    display: block;
    text-align: start;
    color: #f35b2a;
    animation: spin_words 8s infinite;
  }
}


/* lg */
@media (max-width: 1024px) {
  .words1{
    font-size: 38px;
  }
  .display-3 {
    font-size: 3rem; /* Adjust font size */
  }
  .sideHo{
    width: 800px;
    margin-left: 38px;
  }
  .imgCont{
    /* height: 140px; */
    width: 250px;
  }
  .navCont{
    padding: 10px;
    margin-bottom:120px;
    margin-left:80px;
    display: flex;
    height: auto;
  }
 
  .navContText{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 25px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
    font-size: 12px;
    width:130px
  }
  .navContText1{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 25px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
    font-size: 12px;
  }
  .navContText2{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 25px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
    font-size: 12px;
  }
  .navImgEng{
    cursor: pointer;
    width: 40px;
    height: 25px;
  }
  .navImgGer{
    cursor: pointer;
    width:  40px;
    height: 25px;
  }
  .imgDiv{
    width: 50%;
  }
  .contDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 50%; 
  }

  .pad-top{
    padding-top: 20px;
  }


  .words {
    height: 90px; 
    overflow: hidden; 
    position: relative;
  }
  
  .words span {
    background-color: #ffffff;
    height: 100px;
    display: block;
    text-align: start;
    color: #f35b2a;
    animation: spin_words 8s infinite;
  }
}

@media(max-width:1024px){
  .navContText::before{
    content: attr(data-content);
    display: inline-block; 
    color: #595959;
  }
  .navContText{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 25px;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    padding: 0px 8px;
    padding-top: 2px;
    font-size: 12px;
    color: transparent;
  }
}


/* md */
@media (max-width: 768px) {

  .words {
    height: 60px; 
    overflow: hidden; 
    position: relative;
  }
  
  .words span {
    background-color: #ffffff;
    height: 100px;
    display: block;
    text-align: start;
    color: #f35b2a;
    animation: spin_words 8s infinite;
  }
  .pad-top{
    padding-top: 30px;
    padding-left: 10px;
  }
  .words1{
    font-size: 40px;
   
  }
 
  .hamImg{
    top:-60px;
    left:10px;
    position: absolute;
    height:20px;
    width:25px;
  }

  .mobImg{
    left:500px;
    top:-60px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg1{
    left:500px;
    top:-60px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .sideHo{
    width:600px;
    margin-left: 32px;
  }
  .display-3 {
    font-size: 2.5rem; 
  }
  
  .imgCont{
    /* height: 142px; */
    width: 250px;
  }
  .navCont{
    padding: 10px;
    margin-bottom:50px;
    margin-left:0px;
    display: flex;
    height: auto;
    
  }
  
  .navContText{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    align-items: center;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    font-size: 11px;
    width:120px;
    color:transparent;
    margin-top:6px;
   
  }
  .navContText1{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    align-items: center;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    font-size: 11px;
    width:'20px';
    margin-top:6px
  }
  .navContText2{
    border-style: solid;
    border-color: #595959;
    border-width: 1px;
    height: 30px;
    align-items: center;
    color: #595959;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    font-size: 11px;
    width:'20px';
    margin-top:6px
  }
  .navImgEng{
    cursor: pointer;
    width: 40px;
    height: 30px;
    padding-top: 5px;
    
  }
  .navImgGer{
    cursor: pointer;
    width:  40px;
    height: 25px;
    margin-top: 8px;
  }
  .imgDiv{
    width: 50%;
  }
  .contDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 50%; 
  }

}

/* sm */
@media (max-width: 425px) {
  .hamBurg{
    margin-bottom: 200px;
    margin-left: 15px;
  }

  .hamImg{
    top:-50px;
    left:10px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg{
    left:400px;
    top:-50px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg1{
    left:400px;
    top:-50px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .imgCont{
    /* height: 101px; */
    width: 186px;
    
  }
 
  .display-3 {
    font-size: 2rem;
  }
 
  .sideHo{
    margin-left: 0px;
    width:425px;
    padding-bottom: 20px;
  }
  .words{
    height: 60px;
  }
  .navCont {
    /* Hide navbar buttons */
    margin: 0px;
    padding: 0px;
    width: 0;
    display: none;
    height: 0;
  }
  .navContText,.navContText1,.navContText2{
    margin: 0px;
    padding: 0px;
    
  }
  .imgDiv{
    width: 50%;
  }
  .contDiv{
    width: 0%; 
  }
  .navContText{
    height: 0px;
    margin: 0px;
    font-size: 0px;
    width:0px;
    color:transparent;
    display: none;
    margin-top:0px
  }
  .navContText1{
    height: 0px;
    font-size: 0px;
    width:0px;
    margin:0px;
    display:none;
  }
  .navContText2{
    height: 0px;
    font-size: 0px;
    width:0px;
    margin:0px;
    display: none;
  }
  .navImgEng{
    height: 0px;
    width: 0px;
    height: 0px;
    margin: 0px;
    
  }
  .navImgGer{
    height: 0px;
    width:  0px;
    height: 0px;
    margin: 0px;
  }
  .words1{
    font-size: 30px;
  }
  .pad-top{
    padding-top: 0px;
  }
}


@media (max-width:414px) {
  
}


@media (max-width:430px) {
  .hamImg{
    top:-50px;
    left:10px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg{
    left:300px;
    top:-50px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg1{
    left:380px;
    top:-50px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .imgCont{
    /* height: 136px; */
    width: 241px;
  }
  .sideHo{
    margin-left: 0px;
    padding: 0px 3px 0px 21px;
  }
  .pad-top{
    padding-top: 30px;
  }
  .words1{
    font-size: 30px;
  }
  .words{
    height: 60px;
  }
}





@media (max-width:414px) {
  .mobImg{
    left:370px;
    top:-48px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg1{
    left:370px;
    top:-48px;
    position: absolute;
    height:20px;
    width:25px;
  }

  .imgCont{
   
  }
}

@media (max-width:390px) {
  .mobImg{
    left:350px;
    top:-49px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg1{
    left:350px;
    top:-49px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .words{
    height: 60px;
  }
  .imgCont{
    
  }
}

/* xs */
@media (max-width: 375px) {
  .hamImg{
    top:-50px;
    left:10px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg{
    left:335px;
    top:-46px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg1{
    left:335px;
    top:-46px;
    position: absolute;
    height:20px;
    width:25px;
  }

  .imgCont{
   
  }
  .display-3 {
    font-size: 1.5rem;
  }
  
  .sideHo{
    margin-left: 0px;
    width:290px;
    padding-bottom: 20px;
  }
  .words{
    height: 60px;
  }
  .custom-mar{
    margin-right: 1rem;
  }


}


@media (max-width:360px) {
 
  .mobImg{
    left:320px;
    top:-48px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg1{
    left:320px;
    top:-48px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .sideHo{
    margin-left: 0px;
    width:290px;
    padding-bottom: 20px;
  }
  .words{
    height: 60px;
  }
}

@media (max-width:344px) {
 
  .mobImg{
    left:310px;
    top:-47px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg1{
    left:310px;
    top:-47px;
    position: absolute;
    height:20px;
    width:25px;
  }
  
  .imgCont{
    /* height: 101px; */
    width: 186px;
  }
  .sideHo{
    margin-left: 20px;
    width:290px;
    padding-bottom: 20px;
  }
  .words{
    height: 60px;
  }
}

@media (max-width: 320px) {
  .mobImg{
    left:285px;
    top:-47px;
    position: absolute;
    height:20px;
    width:25px;
  }
  .mobImg1{
    left:285px;
    top:-47px;
    position: absolute;
    height:20px;
    width:25px;
  }
  
 
  .display-3 {
    font-size: 1.5rem;
  }
  .imgCont{
    
  }
  
  .sideHo{
    margin-left: 20px;
    width:250px;
    padding-bottom: 20px;
  }
  .words{
    height: 60px;
  }
  .custom-mar{
    margin-right: 1rem;
  }
}
